import { useEffect } from 'react';

const useHubSpotScript = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//js-na1.hs-scripts.com/47149103.js";
        script.type = "text/javascript";
        script.id = "hs-script-loader";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script); // Clean up the script when the component unmounts
        };
    }, []);
};

export default useHubSpotScript;
