import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function Services() {
  return (
      <Container fluid className="project-section">
        <Particle />
        <Container className="text-center">
          <h1 className="project-heading-2">
            <strong className="purple">Services: </strong>Under Construction
          </h1>
          <p style={{ color: "white", fontSize: "1.5rem", marginBottom: "20px" }}>
            We are currently building more amazing services. Stay tuned!
          </p>
          <div className="construction-icon">
            <span className="arrow-icon">☯</span>
            <span className="arrow-icon">☯</span>
            <span className="arrow-icon">☯</span>
          </div>
        </Container>
      </Container>
  );
}

export default Services;
